import { useEffect, useState } from "react";
import { SmartSoftButton } from "soft_digi";
import { CROP_BLUE } from "../../../../services/ImageService";
// import SmartImageDisplay from '../../../../components/site/FormElements/SmartImageDisplay';
import { IoCartOutline } from "react-icons/io5";
import { CUSTOMER_OFFERS } from "../../../../api/OfferUrls";
import { USER_STATEMENT_URL } from "../../../../api/Services/ServiceUrls";
import ImageCarousel from "../../../../components/site/ImageCarousel";
import ProductVariens from "../../../../components/site/ProductVariens";
import { changeDateFormat } from "../../../../services/core/CommonService";
import { DownLoadPdf } from "../../../../services/fileService";
import {
  showAlertAutoClose,
  showAlertAutoCloseHtml,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import "./ProductView.css";
import Rating from './Rating';
// import { IoCartOutline } from "react-icons/io5";
// import { useNavigate } from "react-router";
// import { unstable_HistoryRouter } from "react-router-dom";

const ProductQuickView = ({
  item,
  setLoading,
  offer_type,
  closeModal,
  loginCheck,
  navigate,
  refreshUser,
  handleInputChange,
  updateLike,
}) => {
  //console.log("item ", item);
  //  const [slide,showSlide] = useState(1);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [customNote, setCustomNote] = useState("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleInputChangeNew = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setItemData((prev) => ({ ...prev, [name]: value }));
    if (handleInputChange && handleInputChange instanceof Function) {
      handleInputChange(name, value);
    }
  };

  useEffect(() => {
    setItemData({ ...item, price: 0 });
    // console.log("Item", offer_type);
  }, [item]);

  // useEffect(() => {
  // //  console.log("item data ", itemData);
  //   // console.log("Item", offer_type);
  // }, [itemData]);

  const loginMsg = (msg) => {
    let output =
      "<div><p>Please login to  " +
      msg +
      ".</p><p>If you" +
      " don't" +
      "have an account";
    output +=
      ' you can <a href="/login">login here</a> or <a href="/register">create an account</a>.</p><div>';
    return output;
  };

  const updateWishList = () => {
    if (itemData.wishlist && itemData.wishlist == 1) {
      handleInputChangeNew("wishlist", 0);
    } else {
      handleInputChangeNew("wishlist", 1);
    }
  };

  

  const updateCart = (id, type) => {
    if (!loginCheck()) {
      if (type === "BUY") {
        showAlertAutoCloseHtml(loginMsg("to buy this product"), "info");
      } else {
        showAlertAutoCloseHtml(
          loginMsg("add items to your wishlist/cart"),
          "info"
        );
      }
      return false;
    }

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      //setData([])
    };
    //console.log("data variants " , data.variants);
    // variant selection check needs to be implimented
    if (data.variants && data.variants.length > 0) {
      // if(selectedVariants)
      if (selectedVariants && Object.entries(selectedVariants).length > 0) {
      } else {
        showAlertAutoClose("Please Select the Variant", "error");
        return false;
      }
      //console.log("selected variants ", selectedVariants);
      // return false;
    }

    setLoading(true, "Adding.... Please Wait");
    let url = CUSTOMER_OFFERS.ADD_TO_CART;
    let data_in = {
      sd_offers_id: id,
      type: type,
      offer_type: offer_type,
      variants: selectedVariants,
      price: itemTotalPrice(),
      customNote: customNote,
    };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        let msg = response.data.msg;
        setLoading(false);
        if (type == "BUY") {
          closeModal();
          console.log(offer_type);
          // navigate("/whish-list/checkout/EARN/BUY");
          navigate(
            "/whish-list/checkout/" + offer_type?.toLowerCase() + "/BUY"
          );
        } else {
          if (type == "WISHLIST") {
            updateWishList();
            showAlertAutoClose(msg, "success");
          } else {
            showAlertAutoClose(msg, "success");
          }
          // showAlertAutoClose(msg, "success")
          refreshUser();
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const getImages = () => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading....Please Wait");
    let url = CUSTOMER_OFFERS.GET_ONE;
    let data_in = { id: item.ID,"variants":1 };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        setData(response.data);
        //setItemData(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    getImages();
  }, []);

  const getImageData = () => {
    const output = [];
    for (let i = 0; i < data.images.length; i++) {
      let content = data.images[i].image_content;
      output.push({
        data: (
          <img
            className="is-square smart-image-full"
            src={`data:image/png;base64,${content}`}
            alt=""
          />
        ),
        content: content,
      });
    }
    return output;
  };

  const imagesDisplay = () => {
    // console.log("Data "  ,data," L = ", data.length);
    return (
      <>
        <div className="product-image-container">
          {data && data.images && data.images.length > 0 && (
            <ImageCarousel
              data={getImageData()}
              key="singleImage"
              showButtons={false}
              buttonsClassName={"product-image-buttons"}
              isMulti={true}
            />
          )}
        </div>
        {/* <ImageCarousel data={imageData} key="multiImage" 
        showButtons={false} isMulti={true} showImages={3} dots={true} showImageNumber={slide}/> */}
      </>
    );
  };

  const reviewList = [{ name: "test", rating: 5 }];

  const Review = () => {
    return (
      <>
        {offer_type === "EARN" && (
          <div className="">
            <div className="customer-review-text"> Customer Reviews</div>

            {data?.reviews?.map((item) => ( 
              <>
                {/* <div className="is-flex is-justify-content-space-between"> */}
                {/* <div className="is-flex"> */}
                {/* <figure className="image is-48x48 ">
                      <img
                        className="is-rounded admin-account-image "
                        src={ADMIN_USER_LOGO} alt=""
                      />
                    </figure> */}
                <div className="columns my-2">
                  <div className="column is-6 m-0 py-0">
                    <div className="is-flex is-justify-content-space-between">
                      <div className="customer-review-name">{item.first_name}</div>
                      <div className="mt-2 ml-6">{changeDateFormat(item.last_modified_time)}</div>
                    </div>
                    <div className="">
                      <Rating rating={item.rating} />
                    </div>
                    <div className=" is-flex-wrap-wrap">{item.comment}</div>

                  </div>
                </div>

                {/* <div className="mx-4 mt-3"> */}
                {/* <i
                        className="fa fa-thumbs-up smart-customer-text"
                        aria-hidden="true"
                      ></i> */}
                {/* </div> */}
                {/* </div> */}
                {/* <div className=" has-text-right mt-2">{item.rating}</div> */}

                {/* </div> */}
              </>
            ))}
          </div>
        )}
      </>
    );
  };

  const itemPrice = () => {
    const total_price =
      parseFloat(item.price) +
      (!isNaN(parseFloat(itemData.price)) ? parseFloat(itemData.price) : 0);
    
    const roundedPrice = Math.round(total_price * 100) / 100;
    
    return !isNaN(roundedPrice) ? roundedPrice : 0;
  };
  
  const displayAmount = (totalPrice) => {
    return Math.round(totalPrice * 100) / 100;
  };
  const itemTotalPrice = () => {
    const total_price = itemPrice();
    // console.log(" total price ", total_price);
    const discounted_price = (total_price * itemData.discount) / 100;
    const final_price = total_price - discounted_price;
    return displayAmount(final_price);
  };

  // const earn_crops = () => {
  //   return parseInt(parseInt(itemTotalPrice()) * item["earnFraction"]);
  // };

  // const redeem_crops = () => {
  //   return parseInt(parseInt(itemTotalPrice()) * item["redeemFraction"]);
  // };

  const earn_crops = () => {
    let earn_crop =
      data["earnFraction"] && !isNaN(data["earnFraction"])
        ? data["earnFraction"]
        : 0;
    let total_cost = parseFloat(itemTotalPrice());
    return parseInt(total_cost * earn_crop);
  };

  const redeem_crops = () => {
    let earn_crop =
      data["redeemFraction"] && !isNaN(data["redeemFraction"])
        ? data["redeemFraction"]
        : 0;
    let total_cost = parseFloat(itemTotalPrice());
    return parseInt(total_cost * earn_crop);
  };


  const handleReturnFile = () => {
    setLoading(true, "Loading .. Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let post_data = { id: data.sd_mt_userdb_id };
    const subscription = post(
      USER_STATEMENT_URL.GET_RETURN_POLICY,
      post_data,
      handleError
    ).subscribe((response) => {
      //console.log(response);
      if (response.data.content) {
        DownLoadPdf(response.data.content, "Invoice.pdf");
        // DownLoadPdf(response.data.content, "Invoice.pdf");
      }
      //  setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const returnPolicy = () => { };

  return (
    <>
      <div className=" customer-primary-font  customer-quick-view-container P-5 px-6">
        <div className="smart-modal-close mr-4" onClick={() => closeModal()}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <div className="columns p-4">
          <div className="column is-6" key="keyone"> 
            {imagesDisplay()}
            <div className="mobile-hidden-view">
              {item.offer_ratings > 0 && <Review />}
            </div>
          </div>
          <div className="column is-6 pl-5 p-0" key="keytwo">
            <div className="">
              <div className="smart-offer-view-title p-0">{item.offer_title}</div>
              <div className="is-flex  " key="keyone_3">
                <div className="smart-offer-price-text">
                  <i className="fa fa-star  mr-2" aria-hidden="true"></i>
                  {item.offer_ratings}
                </div>
                <div className="smart-offer-price-text ">
                  <i
                    // onClick={() => updateLike(item.ID)}
                    className="fa fa-thumbs-up  mr-2"
                    aria-hidden="true"
                  ></i>
                  {item.offer_likes}
                </div>
                <div className="smart-offer-shopping-text ml-2 pl-2">
                  {data?.business_data?.shipping}
                </div>
              </div>
              {offer_type === "EARN" && (
                <div className="is-flex  ml-2" key="keyone_2">
                  <div className="smart-offer-view-title " key="keyone_1_2">
                    <span className="customer-secondary-font" >A$ </span> {itemTotalPrice()}
                  </div>

                  <div
                    className="smart-offer-price-text-number"
                    key="keyone_1_1"
                  >
                          {item && item.discount > 0 && ( <s><span className="customer-secondary-font" >A$ </span> {itemPrice()}</s>)}
                  </div>
                  {item && item.discount > 0 && (
                    <div className="smart-offer-discount-text-view">
                      <span className="discount-text">-{item.discount}% </span>
                    </div>
                  )}
                </div>
              )}
              <div className="promos-item-price is-flex  " key="keyone_1">
                <img
                  className="image product-crop-image image  ml-2"
                  src={CROP_BLUE}
                  alt=""

                />
                {offer_type === "EARN" && (
                  <span className="ml-1 smart-offer-view-title ">
                    {" "}
                    {earn_crops()}
                  </span>
                )}
                {offer_type === "REDEEM" && (
                  <span className="ml-1 smart-offer-view-title ">
                    {" "}
                    {redeem_crops()}
                  </span>
                )}
              </div>
              <div className="columns">
                <div className="column">
                  {data.variants && (
                    <ProductVariens
                      data_in={data.variants}
                      setSelectedVariants={setSelectedVariants}
                      setItemData={setItemData}
                      setCustomNote={setCustomNote}
                      customNote={customNote}
                    />
                  )}
                </div>
              </div>
              {loginCheck() && (
                <div className="is-flex columns" key="keyone_4">
                  <div className="column is-5">
                    <SmartSoftButton
                      label="Order Now"
                      // rightIcon="fa fa-file-o"
                      classList={[
                        "smart-customer-primary-button ",
                        "px-5",
                        "is-rounded",
                        "smart-cursor-pointer",
                        "order-now-button"

                      ]}
                      onClick={() => updateCart(item.ID, "BUY")}
                    />
                  </div>

                  <div className="offer-cart-button column is-2 is-size-3 ">
                    <IoCartOutline className="is-size-2 smart-cursor-pointer smart-cart-icon"
                      onClick={() => updateCart(item.ID, "CART")} />
                    {/* <i
                      onClick={() => updateCart(item.ID, "CART")}
                      className="fa fa-shopping-cart is-size-2 smart-cursor-pointer"
                      aria-hidden="true"
                    ></i> */}
                    {/* <IoCartOutline /> */}
                  </div>
                  <div className="wishlist-active-buttons column">
                    <i
                      onClick={() => updateCart(item.ID, "WISHLIST")}
                      className={
                        itemData.wishlist == 1
                          ? "fa fa-heart is-size-2 smart-cursor-pointer"
                          : "fa fa-heart-o is-size-2 smart-cursor-pointer"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              )}
              {item.offer_desc?.length > 0 || item.offer_brief?.length > 0 ? (
                <div className="is-flex is-justify-content-space-between">
                  <p
                    className="smart-cursor-pointer is-size-4"
                    onClick={() => toggleVisibility()}
                  >
                    Description
                  </p>
                  {/* <div></div> */}

                  <i
                    onClick={() => toggleVisibility()}
                    className={
                      isVisible
                        ? "fa fa-minus smart-cursor-pointer mt-2"
                        : "fa fa-plus smart-cursor-pointer mt-2"
                    }
                    aria-hidden="true"
                  ></i>
                </div>) : ""}
              {isVisible && (
                <div>
                  {item.offer_breif?.length > 0 && <div className=" my-3" key="keyone_6">
                    <div className=" has-text-weight-bold">
                      Brief Description
                    </div>
                    <span className="text-warp">{item.offer_breif}</span>
                  </div>}

                  {item.offer_desc?.length > 0 && <>
                    <div>


                      <div className="has-text-weight-bold">
                        Detailed Description
                      </div>
                      <div className="text-warp">{item.offer_desc}</div>
                    </div>
                  </>
                  }
                </div>
              )}
              {data && data.business_data && data.business_data.return && (
                <p className="mt-4 has-text-link smart-cursor-pointer">
                  <span onClick={() => handleReturnFile()}>
                    <b>Return Policy</b>
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="column is-12">
        <div className="is-hidden-tablet">
              {item.offer_ratings > 0 && <Review />}
            </div>
        </div>

      </div>
    </>
  );
};

export default ProductQuickView;


