import { useEffect, useState } from "react";
import { IoCartOutline } from "react-icons/io5";
import { CUSTOMER_OFFERS } from "../../api/OfferUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { ProductQuickView } from "../../pages/Customer";
import {
  CROP_BLUE
} from "../../services/ImageService";
import { displayDots } from "../../services/core/CommonService";
import {
  showAlertAutoClose,
  showAlertAutoCloseHtml,
} from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import SmartImageDisplayNew from "./FormElements/SmartImageDisplayNew";
import ProductVariant from "./ProductVariant";

const ProductDisplay = ({
  item,
  setLoading,
  setData,
  offer_type,
  closeModal,
  loginCheck,
  handleUserChange,
  navigate,
  refreshUser,
}) => {
  const { openModal } = useSiteContext();
  const [itemData, setItemData] = useState({});

  const handleInputChange = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setItemData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const openNewForm = () => {
    let modelObject = {
      body: (
        <ProductQuickView
          item={itemData}
          setLoading={setLoading}
          offer_type={offer_type}
          closeModal={closeModal}
          loginCheck={loginCheck}
          navigate={navigate}
          updateLike={updateLike}
          refreshUser={refreshUser}
          handleInputChange={handleInputChange}
        />
      ),
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const openRateModal = (item, type) => {
    let modelObject = {
      body: (
        <ProductVariant
          item={item}
          setLoading={setLoading}
          closeModal={closeModal}
          type={type}
          executeCart={executeCart}
          offer_type={offer_type}
        />
      ),
      title: "Please Select Variant",
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  // const openNewFormStarRating = () => {
  //   let modelObject = {
  //     body: (
  //       <StarRatings
  //         item={itemData}
  //         setLoading={setLoading}
  //         offer_type={offer_type}
  //         closeModal={closeModal}
  //         loginCheck={loginCheck}
  //         navigate={navigate}
  //         updateLike={updateLike}
  //         refreshUser={refreshUser}
  //         handleInputChange={handleInputChange}
  //       />
  //     ),
  //     modalClass: "crop-customer-modal smart-modal-80",
  //     bodyClose: false,
  //   };
  //   openModal(modelObject);
  // };

  const updateItemLikes = (itemId, amount) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.ID === itemId
          ? { ...item, offer_likes: Math.max(0, item.offer_likes + amount) } // Ensure quantity is not less than 1
          : item
      )
    );
  };

  // const updateItemRatings = (itemId, amount) => {
  //   setData((prevItems) =>
  //     prevItems.map((item) =>
  //       item.ID === itemId
  //         ? { ...item, offer_ratings: Math.max(1, item.offer_ratings + amount) } // Ensure quantity is not less than 1
  //         : item
  //     )
  //   );
  // };

  const updateLike = (id) => {
    if (!loginCheck()) {
      showAlertAutoCloseHtml(loginMsg("like this product"), "info");
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      //setData([])
    };
    setLoading(true, "Loading.... Please Wait");
    let url = CUSTOMER_OFFERS.INSERT_LIKE;
    let data_in = { sd_offers_id: id, offer_type: offer_type };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        let like_value =
          response.data.likes_flag && parseInt(response.data.likes_flag) == 2
            ? -1
            : 1;
            response.data.likes_flag && parseInt(response.data.likes_flag) == 2
            ?
        showAlertAutoClose("Like Removed Successfully", "success"): 
         showAlertAutoClose("Like Added Successfully", "success");
        updateItemLikes(id, like_value);
        // refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loginMsg = (msg) => {
    let output =
      "<div><p>Please login to  " +
      msg +
      ".</p><p>If you" +
      " don't" +
      "have an account";
    output +=
      ' you can <a href="/login">login here</a> or <a href="/register">create an account</a>.</p><div>';
    return output;
  };

  const updateWishList = () => {
    if (itemData.wishlist && itemData.wishlist == 1) {
      //  handleInputChange("wishlist", 0);
    } else {
      //  handleInputChange("wishlist", 1);
    }
  };

  const executeCart = (item, type, variants, price, customNote) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Adding....Please Wait");
    let url = CUSTOMER_OFFERS.ADD_TO_CART;
    let data_in = {
      sd_offers_id: item.ID,
      type: type,
      offer_type: offer_type,
      variants: variants,
      price: price,
      customNote: customNote,
    };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // console.log("response " , response);
        let msg = response.data.msg;
        setLoading(false);
        refreshUser();
        //  if (type == "WISHLIST") {
        // updateWishList();
        //  } else {
        showAlertAutoClose(msg, "success");
        // }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const updateCart = (item, type) => {
    if (!loginCheck()) {
      showAlertAutoCloseHtml(
        loginMsg("add items to your wishlist/cart"),
        "info"
      );
      return false;
    }
    //console.log("item ", item);
    if (
      item.variant_av &&
      item.variant_av > 0 &&
      (type == "CART" || (type == "WISHLIST" && item.wishlist == 0))
    ) {
     // console.log("item " , item);
      openRateModal(item, type);
      return false;
    }
    // variant_av
    executeCart(item, type, {}, item.total_price, "");
  };

  const offer_image = (data) => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <SmartImageDisplayNew
      //  srcType="URL"
        url={url}
        id={id}
       // imageClass=""
       // cacheEnable={true}
      />
    );
  };

  // const earn_crops = () => {
  //   return parseInt(parseInt(item["total_price"]) * item["earnFraction"]);
  // };

  // const redeem_crops = () => {
  //   return parseInt(parseInt(item["total_price"]) * item["redeemFraction"]);
  // };
  const earn_crops = () => {
    let total_cost = parseFloat(item["total_price"]);
    return parseInt(total_cost * item["earnFraction"]);
  };

  const redeem_crops = () => {
    let total_cost = parseFloat(item["total_price"]);
    return parseInt(total_cost * item["redeemFraction"]);
  };
  const displayAmount = (totalPrice) => {
    return Math.round(totalPrice * 100) / 100;
  };
  return (
    <>
      {/* Desktop View */}

      <div className="smart-image-offer-view-box">
        <div className="columns  is-multiline">
          <div className="column is-12">
            <div className=" position-relative">
              <div
                onClick={() => openNewForm()}
                className="smart-image-display-view"
              >
                {offer_image(item)}
              </div>

              <div
                className="customer-promos-like-button"
                onClick={() => updateLike(item.ID)}
              >
                <i className="fa fa-thumbs-up mt-1 pr-1" aria-hidden="true"></i>
                <p>{item.offer_likes}</p>
              </div>
              <div className="customer-promos-star-button">
                <i className="fa fa-star mt-1 pr-1" aria-hidden="true"></i>
                <p>{item.offer_ratings}</p>
              </div>
            </div>
          </div>
          <div className="column is-12 ">
            <div className="columns  is-multiline is-mobile">
              <div className="column is-9 pl-5 pt-5">
                <div className="columns is-multiline is-mobile">
                  <div className="column is-12 p-0 m-0 pl-3 is-flex-mobile">
                    <div
                      onClick={() => openNewForm()}
                      className="promos-item-title "
                      title={item.offer_title}
                    >
                      {displayDots(item.offer_title)}
                    </div>
                  </div>

                  <div className="column is-12 p-0 m-0 pl-3 is-flex-mobile">
                    <div className="">
                      {offer_type === "EARN" && (
                        <div className="is-flex">
                          <div className="promos-item-price ">
                            <div className="">
                              <span className="customer-secondary-font">
                                A${" "}
                              </span>{" "}
                              {displayAmount(item.total_price)}
                            </div>
                          </div>
                          <div className="is-flex">
                            <div
                              className="offer-price-list mt-1"
                              key="keyone_1_1"
                            >
                                   {item && item.discount > 0 && (  <s>
                                <span className="customer-secondary-font">
                                  A${" "}
                                </span>
                                {displayAmount (item.price)}
                              </s>)}
                            </div>
                            <div>
                              {item && item.discount > 0 && (
                                <div className="offer-discount-view mt-1">
                                  <span className=" productDisply-product-Discount">
                                    {" "}
                                    -{item.discount}%
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {offer_type === "REDEEM" && <div className="mt-4"></div>}
                      <div className="promos-item-price is-flex">
                        <img
                          className="image  mt-1 crop-image"
                          src={CROP_BLUE}
                          alt=""
                        />
                        {offer_type === "EARN" && (
                          <span className="ml-1">
                            {" "}
                            {earn_crops(item.total_price)}
                          </span>
                        )}
                        {offer_type === "REDEEM" && (
                          <span className="ml-1">
                            {" "}
                            {redeem_crops(item.redeem_crop_points)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3 pr-4 is-flex-mobile">
                <div className="is-flex is-flex-direction-column pt-2 ">
                  <div className="offer-cart-button">
                    <IoCartOutline
                      className="is-size-3 smart-cursor-pointer smart-cart-icon ml-1"
                      onClick={() => updateCart(itemData, "CART")}
                    />
                    {/* <i
                      className="fa fa-shopping-cart mb-3"
                      aria-hidden="true"
                      onClick={() => updateCart(itemData, "CART")}
                    ></i> */}
                  </div>
                  <div
                    className={
                      itemData.wishlist === 1
                        ? "ml-2 wishlist-active-buttons smart-cursor-pointer"
                        : " ml-2 wishlist-active-buttons smart-cursor-pointer"
                    }
                    onClick={() => updateCart(itemData, "WISHLIST")}
                  >
                    <i
                      className={
                        itemData.wishlist === 1
                          ? "fa fa-heart"
                          : "fa fa-heart-o"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="customer-promos-view-card ">
      <div className="columns is-flex-mobile is-multiline">
        <div className="column is-12 is-mobile smart-product-mobile-view ">
         
        </div>

        <div className="column is-12 is-mobile p-0 smart-product-mobile-view ">
          <div className="promos-item-details-container ml-4">
        

            <StarDisplay rating={item.offer_ratings} />
          
         
            <div className="promos-items-view-box is-flex is-justify-content-space-around">
              <div className="promos-active-buttons"  onClick={() => openNewForm()} >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </div>
              
             
              
             
              
            </div>
          </div>
        </div>
      </div>
      <div className="customer-promos-like-button" onClick={()=>updateLike(item.ID)}>
        <i className="fa fa-thumbs-up mt-1 pr-1" aria-hidden="true"></i>
        <p>{item.offer_likes}</p>
      </div>
      {item&&item.discount !== 0 && <>
        <div className="customer-promos-sales-button">
        <p>Sale {item.discount}%</p>
      </div>
      </>}
  
    </div> */}
    </>
  );
};

export default ProductDisplay;
