import { useEffect, useState } from "react";
// import SmartImageDisplay from '../../../../components/site/FormElements/SmartImageDisplay';
import { CUSTOMER_OFFERS } from "../../api/OfferUrls";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import ProductVariens from "./ProductVariens";
//import "./ProductView.css";
// import { useNavigate } from "react-router";
// import { unstable_HistoryRouter } from "react-router-dom";

const ProductVariant = ({
  item,
  closeModal,
  setLoading,
  type,
  executeCart,
  offer_type,
}) => {
  //  const [slide,showSlide] = useState(1);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [customNote, setCustomNote] = useState("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  //   const handleInputChangeNew = (name, value) => {
  //     //console.log(" name ", name, "   value ", value)
  //     setItemData((prev) => ({ ...prev, [name]: value }));
  //     if (handleInputChange && handleInputChange instanceof Function) {
  //       handleInputChange(name, value);
  //     }
  //   };

  const getData = () => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading....Please Wait..");
    let url = CUSTOMER_OFFERS.GET_ONE;
    let data_in = { id: item.ID,image:0 };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        setItemData({...response.data,price: 0});
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    getData();
  }, [item]);

  const displayAmount = (totalPrice) => {
    return Math.round(totalPrice * 100) / 100;
  };
  const itemPrice = () => {
    const total_price = parseFloat(item.price) + parseFloat(itemData.price);
    return total_price;
  };

  const itemTotalPrice = () => {
    const total_price = itemPrice();
    let item_discount = itemData.discount ? itemData.discount : 0;
    const discounted_price = (total_price * item_discount) / 100;
    let test_amount = total_price - discounted_price;
    return !isNaN(test_amount) ? test_amount : 0;
  };


  // const redeem_crops = () => {
  //   return parseInt(parseInt(itemTotalPrice())  * itemData["redeemFraction"]);
  // };
  const redeem_crops = () => {
    const total_price=parseFloat(parseFloat(itemTotalPrice())* itemData["redeemFraction"])
   let  finaleCrops=!isNaN(total_price) ?total_price :0 ;
    return parseInt(finaleCrops)
  };


  const addToCart = () => {
    if (selectedVariants && Object.entries(selectedVariants).length > 0) {
      executeCart(item, type, selectedVariants, itemTotalPrice(), customNote);
      closeModal();
    } else {
      showAlertAutoClose("Please Select the Variant", "error");
      return false;
    }
  };

  return (
    <>
      <div className="container customer-primary-font  customer-quick-view-container P-5">
        {/* <div className="smart-modal-close" onClick={() => closeModal()}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div> */}
        <div className="columns p-4">
          <div className="column is-12" key="keytwo">
            {/* <div className="column is-12  is-size-5">
                 Please Select the Variant
              </div>    */}

            <div className="">
              <div className="columns">
                <div className="column">
                  {itemData.variants && Array.isArray(itemData.variants) && (
                    <ProductVariens
                      data_in={itemData.variants}
                      setSelectedVariants={setSelectedVariants}
                      setItemData={setItemData}
                      setCustomNote={setCustomNote}
                      customNote={customNote}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="column is-12 has-text-right">
              {offer_type == "EARN" && (
                <span>Total Price : {displayAmount(itemTotalPrice())}</span>
              )}
              {offer_type == "REDEEM" && (
                <span>Total CROPs : {redeem_crops()}</span>
              )}
            </div>
            <div className="column is-12 has-text-centered">
              <button
                className="smart-customer-primary-button"
                onClick={() => addToCart()}
              >
                {type == "WISHLIST" ? "Add to Wishlist" : "Add to Cart"}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductVariant;

/*
 <div className='columns is-multiline'>
              <div className='column is-12'>
                <div className='quick-view-image'>
                  {offer_image(item)}
                </div>

              </div>
              <div className='column is-4'>

              </div>
              <div className='column is-4'>
                {offer_image(item)}
              </div>
              <div className='column is-4'>
                {offer_image(item)}
              </div>
            </div>

        */
