import { useEffect, useState } from "react";
import {
  SmartSofFile,
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import {
  ALLOW_ALPHABET_SPACE,
  ALLOW_NUMERIC,
} from "../../../services/PatternSerivce";
// import { ADMIN_USER_LOGO } from "../../../services/ImageService";
import ProfileSideNav from "./ProfileSideNav";
// import SmartMobileNumber from "../../../components/site/FormElements/SmartMobileNumber";
import { CUSTOMER_MY_ACCOUNT } from "../../../api/OfferUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { getImageContent } from "../../../services/fileService";
import { showAlertAutoClose } from "../../../services/notifyService";
import {
  admin_interest_select,
  admin_loyalty_select,
  admin_states_select,
} from "../../../services/site/SelectBoxServices";
import { get, post } from "../../../services/smartApiService";
// import OrderCancellation from "./OrderCancellation";
import {
  //  useNavigate,
  useParams,
} from "react-router-dom";
import SmartImageEditor from "../../../components/site/FormElements/SmartImageEditor";
import SmartHeader from "../../../components/site/SmartHeader";
import { base64ToFileSize } from "../../../services/core/FileService";
import { decrypt_data } from "../../../services/sessionService";
import EmailChange from "./EmailChange";
import MobileChange from "./MobileChange";
import DeleteAccount from "./DeleteAccount";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";

const MyProfile = () => {
  //const [data, setData] = useState(null);

  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [formAddressData, setFormAddressData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, refreshUser } = useSiteContext();
  const [loyalty, setLoyalty] = useState([]);
  const [states, setStates] = useState([]);
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const [interest, setInterest] = useState([]);
  const { openModal, closeModal } = useSiteContext();
  const [maximumDate, setMaximumDate] = useState(new Date());

  const handleInputChange = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if(name=='age_group'){
      getAgeGroup(value)
    }
  };

  const handleInputAddressChange = (name, value) => {
    setFormAddressData((prev) => ({ ...prev, [name]: value }));
    if (name == "profile_image") {
      // resetData();
    }
  };

  const resetData = () => {
    handleInputChange("profile_image", "");
    refreshUser()
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // const loadTableData = () => {
  //   //setLoading(true, "Loading....Please Wait");
  //   const handleError = (errorMessage) => {
  //     // showAlertAutoClose(errorMessage);
  //     setLoading(false);
  //   };
  //   let post_data = { id: decrypted_id };
  //   const subscription = post(
  //     ORDER_ITEMS_URLS.GET_ONE,
  //     post_data,
  //     handleError
  //   ).subscribe((response) => {
  //     setData(response.data);
  //     setLoading(false);
  //   });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // };
  // useEffect(() => {
  //   // loadTableData();
  // }, [decrypted_id]);

  const loadProfileData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.GET_PROFILE,
      handleError
    ).subscribe((response) => {
      setFormData(response.data.userData);
      setFormAddressData(response.data.addrData);
      setLoading(false);
      window.scrollTo(0, 0);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadSelectOptions = () => {
    // load sector options
    admin_loyalty_select((data) => setLoyalty(data));
    //
    admin_interest_select((data) => setInterest(data));
    // states selection
    admin_states_select((data) => setStates(data));
  };

  useEffect(() => {
    loadSelectOptions();
    loadProfileData();
    const currentDate = new Date();
    const newDate = new Date(maximumDate);
    newDate.setFullYear(currentDate.getFullYear() - 13);
    setMaximumDate(newDate);
    console.log(maximumDate);
  }, []);

  const getAgeGroup=(value)=>{
    const currentDate = new Date();
    const newDate = new Date(maximumDate);
    newDate?.setFullYear(currentDate?.getFullYear() - value?.value);
    setMaximumDate(newDate);
  }

  const age_group_options = [
    { value: "19", label: "18-25 Years" },
    { value: "26", label: "26-35 Years" },
    { value: "36", label: "36-45 Years" },
    { value: "46", label: "46-55 Years" },
    { value: "55", label: "over 55 Years" },
  ];

  const handleFormSubmit = () => {
    window.scrollTo(0, 0);
    // console.log("formdata", formData);
    setFormSubmit(true);
    if (formErrors && formErrors.profile_image) {
      return false;
    }
    // console.log("form errors " , formErrors);
    // if (!ValidateFormNew(formData,[...formElements,...Elements,...secForm])) {
    //    return false;
    //  }
    // setFormSubmit(true);

    if (!ValidateFormNew(formAddressData, Elements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, " Updating....Please Wait");
    let url = CUSTOMER_MY_ACCOUNT.UPDATE_PROFILE;
    let data_in = { ...formData, ...formAddressData };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {

        showAlertAutoClose("Profile Updated successfully", "success");
        setLoading(false);
        loadProfileData();
        setFormSubmit(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 2500);
        refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  useEffect(() => {
    if (!formSubmit) {
      window.scrollTo(0, 0);
    }
  }, [formSubmit]);

  const Validations = {
    // first_name: [SmartValid.required("First Name is Required")],
    // middle_name: [SmartValid.required("Middle Name is Required")],
    // last_name: [SmartValid.required("Last Name is Required")],
    // age: [SmartValid.required("Age is Required")],
    // DOB: [SmartValid.required("DOB is Required")],
    // add_1: [SmartValid.required("Address 1 is Required")],
    // add_2: [SmartValid.required("Address 2  is Required")],
    // city: [SmartValid.required("City is Required")],
    // state: [SmartValid.required("State is Required")],
    // pincode: [SmartValid.required("Pincode is Required")],
    image: [
      SmartValid.custom((value) => {
        // allowed profile size 800 kb
        let image_size = base64ToFileSize(value && value.content ? value.content : "");
        return image_size > 800 ? "Size should be less than 800 KB" : "";
      })
    ],

  };
  const formValidations = {
    zipCode: [
      SmartValid.required("Pincode is Required"),
      SmartValid.minLength("Minimum 4 Digit", 4),
    ],
    addressLine: [SmartValid.required("Address Line1 is Required")],
    state: [SmartValid.required("State is Required")],
  };

  const options = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Not Specified", label: "Not Specified" },
  ];

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "4",
      name: "first_name",
      element: {
        label: "First Name ",
        inputProps: {
          disabled: true,
        },
      },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "middle_name",
      element: {
        label: "Middle Name",
        inputProps: {
          disabled: true,
        },
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "last_name",
      element: {
        label: "Last Name",
        inputProps: {
          disabled: true,
        },
      },
    },
    {
      type: "SELECT_BOX",
      width: "4",
      name: "age_group",
      element: {
        label: "Select Age Group",
        inputProps: {
          // disabled: true,
        },
        options: age_group_options,
      },
    },
    {
      type: "DATE",
      width: "4",
      name: "age",
      element: {
        label: "Date of Birth (DD/MM)",
        // validations: Validations.DOB,
        inputProps: {
           disabled: true,
        },
        dateFormat: "dd/MM",
        maxDate: maximumDate, 
      },
    },
    /*
    {
      type: "TEXT_BOX",
      width: "4",
      name: "email_id",
      element: {
        label: "Email",
        disabled: true
      },
    },*/
  ];
  const Elements = [
    {
      type: "TEXT_BOX",
      width: "4",
      name: "addr_one",
      element: {
        label: "Address Line 1",
        // pattern: ALLOW_ALPHABET,
        isRequired: true,
        max: "255",
        validations: formValidations.addressLine,
        
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "addr_two",
      element: {
        label: "Address Line 2",
        // pattern: ALLOW_ALPHABET,
        max: "255",
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "city",
      element: {
        label: "Address Line 3",
        // pattern: ALLOW_ALPHABET_SPACE,
        max: "255",
      },
    },
    {
      type: "SELECT_BOX",
      width: "4",
      name: "state",
      element: {
        label: "State",
        options: states,
        validations: formValidations.state,
        isRequired: true,
      },
    },

    {
      type: "TEXT_BOX",
      width: "4",
      name: "pincode",
      element: {
        label: "Pincode",
        pattern: ALLOW_NUMERIC,
        max: 4,
        validations: formValidations.zipCode
      },
    },
  ];
  const addressForm = () => {
    // const state_options = [
    //   { value: "1", label: "13-18 Years" },
    //   { value: "2", label: "19-25 Years" },
    //   { value: "3", label: "26-35 Years" },
    //   { value: "4", label: "36-45 Years" },
    //   { value: "5", label: "46-55 Years" },
    //   { value: "6", label: "over 55 Years" },
    // ];

 
    return (
      <SmartSoftForm
        formData={formAddressData}
        setFormData={handleInputAddressChange}
        elements={Elements}
        formSubmit={formSubmit}
        handleErrorChange={handleErrorChange}
      />
    );
  };

  const loyaltyInterestForm = () => {
    const secForm = [
      {
        type: "CHECK_RADIO",
        width: "4",
        name: "gender",
        element: {
          label: "Gender",
          options: options,
          type: "radio",
          isMulti: true,

          // validations: formValidations.input_one,
        },
      },
      {
        type: "SELECT_BOX",
        width: "4",
        name: "loyalty",
        element: {
          label: "Loyalty Program:",
          options: loyalty,
          // isHorizontal: true,
          isMulti: true,
          classList: ["smart-input-label-width-60"],
        },
      },
      {
        type: "SELECT_BOX",
        width: "4",
        name: "interest",
        element: {
          label: "Interest ",
          options: interest,
          isMulti: true,
          //isHorizontal: true,
          classList: ["smart-input-label-width-60"],
        },
      },
    ];

    return (
      <>
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={secForm}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </>
    );
  };

  const terms_and_conditions = () => {
    const loyalty = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    return (
      <>
        <SmartSoftCheckRadioSwitch
          isMulti={true}
          options={loyalty}
          type={"radio"}
          label="Market Notification:"
          name="marketting"
          isHorizontal="true"
          value={formData?.marketting}
          onChange={(value) => handleInputChange("marketting", value)}
        />

        <SmartSoftCheckRadioSwitch
          isMulti={true}
          options={loyalty}
          type={"radio"}
          label="Newsletter:"
          name="newsletter"
          isHorizontal="true"
          isRight
          value={formData?.newsletter}
          onChange={(value) => handleInputChange("newsletter", value)}
        />
      </>
    );
  };
  const deleteImage = () => {
    setFormData((prev) => ({ ...prev, profile_image: null }));
  };

  const openImageModalOnEditNew = (val) => {
    let value = [val];
    if (value) {
      const lastIndex = value.length - 1;
      openImageModal(value[lastIndex], lastIndex);
    }
  };

  const setImage = (image_content, imageData, index) => {
    let name = "profile_image";
    imageData.content = image_content.split(",")[1];
    setFormData((prev) => ({ ...prev, [name]: imageData }));
  };

  const openImageModal = (imageData, index) => {
    if (imageData) {
      let modelObject = {
        body: (
          <SmartImageEditor
            src={imageData.content}
            setImage={(image) => setImage(image, imageData, index)}
            closeModal={closeModal}
            enableOthers={false}
          />
        ),
        modelClass: "customer-model-layout smart-modal-90",
        bodyClose: false,
      };
      openModal(modelObject);
    }
  };


  const handleMobileClick = () => {
    setLoading(true, "OTP sending....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.SENT_OTP_EMAIL,
      handleError
    ).subscribe((response) => {
      // console.log(response);
      setLoading(false);
      openNewForm(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };



  const openNewForm = () => {
    let modelObject = {
      body: (
        <MobileChange
          // data={dataIn}
          setLoading={setLoading}
          closeModal={closeModal}
          loadProfileData={loadProfileData}
          handleMobileClick={handleMobileClick}
          refreshUser={refreshUser}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };


  const openDeleteAccount = () => {
    let modelObject = {
      body: (
        <DeleteAccount
          // data={dataIn}
          setLoading={setLoading}
          closeModal={closeModal}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const handleEmailClick = () => {
    setLoading(true, "OTP sending....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.SEND_OTP_MOBILE,
      handleError
    ).subscribe((response) => {
      // console.log(response);
      setLoading(false);
      openNewFormemail(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const openNewFormemail = () => {
    let modelObject = {
      body: (
        <EmailChange
          // data={dataIn}
          setLoading={setLoading}
          closeModal={closeModal}
          handleEmailClick={handleEmailClick}
          loadProfileData={loadProfileData}
          refreshUser={refreshUser}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  return (
    <>
      <div className="columns  ">
        {/* <div className="column is-2  ">
          <ProfileSideNav />
        </div> */}
        <div className="column is-12 px-6 m-4">
          <div className="mb-3 pl-1">
            <SmartHeader title={"My Profile"} />
          </div>
          <div>
            <div className=" has-background-white smart-customer-input pl-4 pr-6">
              <span className="ml-6 is-size-5 mt-6  has-text-weight-bold  has-text-centered customer-header">
                My Profile
              </span>

              <div className="columns is-multiline ml-5 mr-5 is-centered">
                <div className="column  is-4 pt-5 has-text-centered mt-6 MyProfile-uplode-box crop-customer-profile-image">
                  <div className="">
                    <figure className="image is-128x128 ml-5 mb-4 customer-profile-image crop-customer-profile-image-two">
                      <img
                        className="is-rounded"
                        src={getImageContent(formData?.profile_image)}
                        alt=""
                      />
                    </figure>
                    <div className="smart-crop-image-edit">
                  <i
                    className="fa fa-pencil-square-o  image-edit-options"
                    onClick={() =>
                      openImageModalOnEditNew(formData?.profile_image)
                    }
                  ></i>
                  <i
                    className="fa fa-trash image-edit-options "
                    onClick={() => deleteImage()}
                  ></i>
                </div>

                    <SmartSofFile
                      placeHolder="Upload Image"
                      value={formData?.profile_image}
                      onChange={(value) => {
                        // console.log("profile pic chnaged ")
                        openImageModal(value, 0)
                        handleInputChange("profile_image", value)
                      }
                      }
                      errorEnable={formSubmit}
                      fileNameEnable={false}
                      validations={Validations.image}
                      errorUpdate={(value) => {
                        handleErrorChange("profile_image", value)
                      }
                      }
                    />
                    {/* <div className="mt-2 is-size-6 ml-3 smart-editgreen-icon is-clickable"> 
                 <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  </div> */}
                {/* <div className="mt-2 is-size-6 ml-3 delete-color is-clickable" onClick={() => resetData()}> 
                  <i class="fa fa-times" aria-hidden="true"></i>
                  </div> */}
                  
                  </div>
                </div>

                <div className="column  is-4 has-text-centered customer-profile-email-button">
                  <span className="has-text-weight-bold">
                    <i
                      className="fa fa-mobile pr-2 is-size-3 MyProfilemobileiconcolor"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="  has-text-weight-medium mb-6">
                    {formData?.mobile_no}
                  </div>
                  <SmartSoftButton
                    label="Update Mobile"
                    classList={["smart-customer-primary-button"]}
                    onClick={() => openNewForm()}
                  // onClick={handleFormSubmit}
                  />
                </div>

                <div className="column  is-4 has-text-centered customer-profile-email-button">
                  <span className="has-text-weight-bold">
                    <i
                      className="fa fa-envelope   is-size-3 MyProfilemobileiconcolor"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className=" mb-6 has-text-weight-medium">
                    {formData?.email_id}
                  </div>
                  <SmartSoftButton
                    label="Update Email "
                    classList={["smart-customer-primary-button"]}
                    // onClick={handleFormSubmit}.
                    onClick={() => openNewFormemail()}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column  mx-3">
                  <div className="smart-profile-header">My Details</div>
                  <SmartSoftForm
                    formData={formData}
                    setFormData={handleInputChange}
                    elements={formElements}
                    formSubmit={formSubmit}
                    handleErrorChange={handleErrorChange}
                    onChange={(value) => handleInputChange("input_four", value)}
                  />
                  {/* {SecondInput()} */}
                  <div className="smart-profile-header">Address Details</div>
                  {addressForm()}
                  <div className="smart-profile-header">Other Details</div>
                  {loyaltyInterestForm()}
                  {terms_and_conditions()}

                  <div className="has-text-centered mt-5 mb-6">
                    <SmartSoftButton
                      label="Update Profile"
                      classList={["smart-customer-primary-button"]}
                      onClick={handleFormSubmit}
                    />

                  <div className="account-text has-text-danger has-text-centered mt-4">
                    <span onClick={() => openDeleteAccount()}>
                      Delete My Account?
                      </span>
                  </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
