import { Route, Routes } from "react-router-dom";
import {
  MyAccount,
  MyCROPCard,
  MyProfile,
  PinChange,
  ProfileSideNav,
} from "../../pages/Customer";
import MyOrders from "../../pages/Customer/ProfileButton/MyOrders";
import OrderInfo from "../../pages/Customer/ProfileButton/OrderInfo";

const ProfileButton = () => {
  return (
    <Routes>
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/my-account" element={<MyAccount />} />
      <Route path="/my-orders" element={<MyOrders />} />
      <Route path="/my-orders/:tabtype" element={<MyOrders />} />
      <Route path="/my-prders/:tabtype" element={<MyOrders />} />
      <Route path="/order-info/:id" element={<OrderInfo />} />
      <Route path="/my-crop-card" element={<MyCROPCard />} />
      <Route path="/pin-change" element={<PinChange />} />
      <Route path="/side-nav" element={<ProfileSideNav />} />
    </Routes>
  );
};

export default ProfileButton;
